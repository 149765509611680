video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.landingLogoImg {
  width: 50px;
  max-height: 50px;
}

.landingLogoText {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "ColfaxAI", Helvetica, sans-serif;
  font-size: 18px;
  margin-left: -10px !important;
}

.landingLogo {
  position: fixed;
  width: 600px;
  padding: 30px;
}

.landingBetaImg {
  flex: 0 0 auto;
  height: 100%;
  margin-left: 12px;
  width: auto;
}

.actionContent {
  position: fixed;
  -webkit-font-smoothing: antialiased;
  bottom: 0;
  background: rgba(17, 1, 95, 0.2);
  font-size: 11px;
  width: 100%;
  padding: 20px;
}

.titleContent {
  position: fixed;
  font-size: 76px;
  font-weight: 700;
  line-height: 0.9;
  bottom: 380px;
  left: 40px;
  color: #fff;
}

.titleContent p {
  margin-top: 14px;
  margin-left: 12px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 2px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .landingLogo {
    position: fixed;
    width: 100%;
    padding: 20px;
  }

  .titleContent{
    width: 90%;
    bottom: 320px;
    font-size: 56px;
    left: 20px;
  }

  .titleContent p {
    font-size: 16px;
    margin-left: 6px;
  }
}

.signLink,
.formbtn {
  border: 1px solid #fff;
  outline: 2px solid transparent;
  background-color: transparent;
  outline-offset: 2px;
  padding: 8px;
  color: #fff;
}

.signLink {
  font-size: 13px;
  letter-spacing: 2px;
}

.signLink:hover {
  background-color: #fff;
  color: rgb(17, 1, 95) !important;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 20px;
  margin: 0 auto;
}

.formelement,
.formlabel {
  width: 400px;
  font-size: 1rem;
  line-height: 130%;
  display: block;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

.signLink {
  align-self: center;
  font-size: 11px;
}

  .formContainer {
    width: 100%;
    padding-right: 0px;
  }

  .formelement,
  .formlabel {
    width: 100%;
  }
}

.formelement {
  height: 3rem;
}

.formlabel {
  letter-spacing: 1px;
  color: #f1f1f1;
}

.forminput {
  padding: 20px;
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
  border-radius: 0px;
  color: #fff;
}

.inputerror {
  border: 1px solid red;
  color: red;
}